.regionPosition {
  display: flex;
  align-items: flex-start;
}

.char {
  margin-right: var(--space-l);

  color: var(--color-medium-gray);
  font-size: var(--size-b);
  font-family: var(--font-hero);
  line-height: 1.5;
}

.link {
  display: block;
  margin-top: var(--space-s);
}
