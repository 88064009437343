.wrapperContent {
  max-width: 522px;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.column {
  width: 100%;
  max-width: 192px;
  margin-right: var(--space-xxl);

  &:last-child {
    margin-right: 0;
  }
}

.regionPosition {
  margin-bottom: var(--space-xxl);
}

@media (--mobile) {
  .content {
    padding: 0 var(--space-s);
  }

  .column {
    width: unset;
    max-width: unset;
  }
}
